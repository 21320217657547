<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand class="navbar-logo">
      <!--CIcon
        custom-class-name="sidebar-brand-full"
        :icon="logoNegative"
        :height="35"
      /-->
      <BeerLogo size="40" style="margin-top: -4px" />
      <div style="padding-left: 10px">SEIDLWERTUNG</div>
    </CSidebarBrand>
    <AppSidebarNav />
    <!--CSidebarToggler class="d-none d-lg-flex" /-->
    <!-- @click="$store.commit('toggleUnfoldable')" -->
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoSeidlwertung } from '@/assets/brand/logo-beer'
import { sygnet } from '@/assets/brand/sygnet'
import BeerLogo from '@/assets/brand/BeerLogo'
export default {
  name: 'AppSidebar',
  components: {
    BeerLogo,
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      logoSeidlwertung,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>

<style>
.navbar-logo {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bolder;
  font-size: 1.4rem;
  color: #ffffff;
  text-shadow: -1px 1px #bd8629;
}
</style>
