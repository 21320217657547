<template>
  <div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader ref="header" />
      <div class="body flex-grow-1 px-3">
        <CContainer lg>
          <router-view
            @error="handleError"
            @toast="showToast"
            @changes="setUnsavedChanges"
          />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
  methods: {
    handleError(err) {
      this.$emit('error', err)
    },
    showToast(toast) {
      this.$emit('toast', toast)
    },
    setUnsavedChanges(unsavedChanges) {
      if (this.$refs.header) this.$refs.header.setUnsavedChanges(unsavedChanges)
    },
  },
  mounted() {
    this.setUnsavedChanges(false)
  },
}
</script>
