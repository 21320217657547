import store from '../../store'
import router from '../../router'

const ENDPOINT = process.env.VUE_APP_BACKEND_URL + '/api'

const login = async (mail, password) =>
  new Promise((resolve, reject) => {
    const context = this
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mail,
        password,
      }),
    }
    fetch(`${ENDPOINT}/authenticate`, requestOptions)
      .then((response) => response.json())
      .then(async (payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.token)
        }
      })
      .catch((err) => {
        context.error(err.message)
      })
  })

const register = async (firstname, lastname, mail, phoneNr, password) =>
  new Promise((resolve, reject) => {
    const context = this
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        firstname,
        lastname,
        mail,
        phone_nr: phoneNr,
        password,
      }),
    }
    fetch(`${ENDPOINT}/register`, requestOptions)
      .then((response) => response.json())
      .then(async (payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve()
        }
      })
      .catch((err) => {
        context.error(err.message)
      })
  })

// eslint-disable-next-line no-async-promise-executor
const loadEvents = async () =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/events`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadAllEvents = async () =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/events/all`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadEvent = async (eventId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const editEvent = async (eventId, update) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify(update),
    }
    fetch(`${ENDPOINT}/event/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const editTeam = async (eventId, teamId, update) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify(update),
    }
    fetch(`${ENDPOINT}/event/${eventId}/team/${teamId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const deleteTeam = async (eventId, teamId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}/team/${teamId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const deleteEvent = async (eventId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadSponsors = async () =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/sponsors`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadAllSponsors = async () =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/sponsors/all`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadSponsor = async (sponsorId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/sponsor/${sponsorId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

const loadEventSponsors = async (eventId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}/sponsors`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const editSponsor = async (sponsorId, update) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify(update),
    }
    fetch(`${ENDPOINT}/sponsor/${sponsorId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

const createSponsor = async (name, image) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify({
        name,
        image,
      }),
    }
    fetch(`${ENDPOINT}/sponsor/create`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const deleteSponsor = async (sponsorId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/sponsor/${sponsorId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

const addSponsorToEvent = async (eventId, sponsorId, sorting) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify({
        sorting,
      }),
    }
    fetch(`${ENDPOINT}/event/${eventId}/sponsor/${sponsorId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

const removeSponsorFromEvent = async (eventId, sponsorId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}/sponsor/${sponsorId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const editEventSponsor = async (eventId, sponsorId, update) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify(update),
    }
    fetch(`${ENDPOINT}/event/${eventId}/sponsor/${sponsorId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadEventUser = async (eventId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}/users`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadUser = async () =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/user`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadUserById = async (userId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/user/${userId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadJoinInformation = async (token) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/invite/${token}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadTeams = async (eventId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}/teams`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadTeam = async (eventId, teamId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}/team/${teamId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const createShareLink = async (eventId) =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.generateBase64BasicAuth(),
      },
      body: JSON.stringify({
        event_id: eventId,
      }),
    }
    fetch(`${ENDPOINT}/invite`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadFooters = async (eventId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}/footers`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadFooter = async (eventId, footerId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}/footer/${footerId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const editFooter = async (eventId, footerId, update) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify(update),
    }
    fetch(`${ENDPOINT}/event/${eventId}/footer/${footerId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

const createFooter = async (eventId, text, sorting) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify({
        text,
        sorting,
      }),
    }
    fetch(`${ENDPOINT}/event/${eventId}/footer/create`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const deleteFooter = async (eventId, footerId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}/footer/${footerId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

const createEvent = async (title, description, date, type) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify({
        title,
        description,
        date,
        type,
      }),
    }
    fetch(`${ENDPOINT}/event/create`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

const createTeam = async (eventId, title, description) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify({
        title,
        description,
      }),
    }
    fetch(`${ENDPOINT}/event/${eventId}/team/create`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

const createTeamEntry = async (
  eventId,
  teamId,
  amount,
  // eslint-disable-next-line no-async-promise-executor
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify({
        amount,
      }),
    }
    fetch(`${ENDPOINT}/event/${eventId}/team/${teamId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

const sendMessage = async (
  projectId,
  teamId,
  message,
  // eslint-disable-next-line no-async-promise-executor
) =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.generateBase64BasicAuth(),
      },
      body: JSON.stringify({
        message,
      }),
    }
    fetch(
      `${ENDPOINT}/projects/${projectId}/team/${teamId}/messages`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

const joinEvent = async (
  token,
  // eslint-disable-next-line no-async-promise-executor
) =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/join/token/${token}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve()
        }
      })
      .catch((err) => reject(err))
  })

const joinEventViaCode = async (
  code,
  // eslint-disable-next-line no-async-promise-executor
) =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/join/code/${code}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve()
        }
      })
      .catch((err) => reject(err))
  })

const createPaymentSession = async (
  title,
  description,
  date,
  // eslint-disable-next-line no-async-promise-executor
) =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.generateBase64BasicAuth(),
      },
      body: JSON.stringify({
        title,
        description,
        date,
      }),
    }
    fetch(`${ENDPOINT}/checkout`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const createContribution = async (requirementId) =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/contribute/${requirementId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const deleteContribution = async (requirementId) =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/contribute/${requirementId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

async function logout() {
  store.commit('updatePassword', undefined)
  store.commit('updateToken', undefined)
  await router.push('/login')
}

const isLoggedIn = async () => {
  const token = await store.getters.getToken
  return token && !token
}

const generateBase64BasicAuth = async () => {
  const mail = await store.getters.getMail
  const token = await store.getters.getToken

  let raw = `${mail}:${token}`
  // eslint-disable-next-line no-undef
  let encodedAuth = base64.encode(raw)
  return `Basic ${encodedAuth}`
}

// eslint-disable-next-line no-async-promise-executor
const isAdmin = async () =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/user/is-admin`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadInvoices = async () =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/invoices`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadInvoicesByEvent = async (eventId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}/invoices`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadInvoice = async (invoiceId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/invoice/${invoiceId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

const createInvoice = async (eventId, price) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify({
        eventId,
        price,
      }),
    }
    fetch(`${ENDPOINT}/invoice/create`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const editInvoice = async (invoiceId, update) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify(update),
    }
    fetch(`${ENDPOINT}/invoice/${invoiceId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const deleteInvoice = async (invoiceId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/invoice/${invoiceId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadDisplaysByEvent = async (eventId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/event/${eventId}/displays`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const loadDisplay = async (displayId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/display/${displayId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor

const editDisplay = async (displayId, update) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify(update),
    }
    fetch(`${ENDPOINT}/display/${displayId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })
const createDisplay = async (
  eventId,
  number,
  name,
  background,
  footer,
  text_color,
  color,
  font,
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
      body: JSON.stringify({
        eventId,
        number,
        name,
        background,
        footer,
        text_color,
        color,
        font,
      }),
    }
    fetch(`${ENDPOINT}/event/${eventId}/display/create`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload.link)
        }
      })
      .catch((err) => reject(err))
  })

// eslint-disable-next-line no-async-promise-executor
const deleteDisplay = async (displayId) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await generateBase64BasicAuth(),
      },
    }
    fetch(`${ENDPOINT}/display/${displayId}`, requestOptions)
      .then((response) => response.json())
      .then((payload) => {
        if (payload.error === true) {
          reject(payload)
        } else {
          resolve(payload)
        }
      })
      .catch((err) => reject(err))
  })

export default {
  login,
  register,
  loadEvents,
  loadAllEvents,
  loadTeams,
  loadTeam,
  loadEvent,
  loadEventUser,
  loadUserById,
  editEvent,
  editTeam,
  deleteTeam,
  editFooter,
  deleteFooter,
  createFooter,
  deleteEvent,
  loadSponsors,
  createSponsor,
  editSponsor,
  editEventSponsor,
  deleteSponsor,
  loadSponsor,
  loadAllSponsors,
  loadEventSponsors,
  addSponsorToEvent,
  removeSponsorFromEvent,
  loadUser,
  loadJoinInformation,
  createShareLink,
  loadFooters,
  loadFooter,
  createEvent,
  createTeam,
  createTeamEntry,
  sendMessage,
  joinEvent,
  joinEventViaCode,
  createContribution,
  deleteContribution,
  createPaymentSession,
  isLoggedIn,
  logout,
  generateBase64BasicAuth,
  isAdmin,
  loadInvoices,
  loadInvoicesByEvent,
  loadInvoice,
  createInvoice,
  editInvoice,
  deleteInvoice,
  loadDisplaysByEvent,
  loadDisplay,
  createDisplay,
  editDisplay,
  deleteDisplay,
}
