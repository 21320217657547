export default (isAdmin) => {
  if (isAdmin) {
    return [
      {
        component: 'CNavItem',
        name: 'Veranstaltungen',
        to: '/events',
        icon: 'cil-list',
      },
      {
        component: 'CNavItem',
        name: 'Sponsoren',
        to: '/sponsors',
        icon: 'cil-user',
      },
      {
        component: 'CNavItem',
        name: 'Rechnungen',
        to: '/invoices',
        icon: 'cil-chart-pie',
      },
    ]
  } else {
    return [
      {
        component: 'CNavItem',
        name: 'Veranstaltungen',
        to: '/events',
        icon: 'cil-list',
      },
      {
        component: 'CNavItem',
        name: 'Sponsoren',
        to: '/sponsors',
        icon: 'cil-user',
      },
    ]
  }
}
