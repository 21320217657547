<template>
  <div
    :id="modalId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <CCloseButton class="float-end me-3 flex-center" @click="close" />
        </div>
        <div class="modal-body">{{ content }}</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="close">
            {{ button_text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from '@coreui/coreui'
export default {
  name: 'ErrorModal',
  props: ['modalId'],
  data() {
    return {
      modal: undefined,
      title: 'Error',
      content: 'An error occurred',
      button_text: 'OK',
    }
  },
  methods: {
    handle(err) {
      this.title = err.name
      this.content = err.message
      this.modal.show()
    },
    close() {
      this.modal.hide()
    },
    showMessage(errMessage) {
      this.content = errMessage
      this.modal.show()
    },
  },
  mounted() {
    this.modal = new Modal(document.getElementById(this.$props.modalId))
  },
}
</script>
